import { httpClient, parseQueryParam } from '@/app/shared/services';

export const COURIER_STORE_MODULE_NAME = 'couriers';

export const courierStoreModule = {
  namespaced: true,
  state: {
    data: [],
    meta: {},
    courier: {},
  },
  getters: {
    autocompleteCouriers: (state) => state.data.map((p) => ({ value: p.id, label: p.name })),
  },
  mutations: {
    SET_COURIER_DATA(state, payload) {
      state.data = payload;
    },
    SET_COURIER_META(state, payload) {
      state.meta = payload;
    },
    SET_COURIER(state, payload) {
      state.courier = payload;
    },
  },
  actions: {
    fetchCouriers({ commit }, queryParams) {
      return new Promise((res, rej) => {
        const params = parseQueryParam(queryParams);
        httpClient
          .get(`/couriers${params}`)
          .then((response) => {
            const { data, meta } = response.data;
            commit('SET_COURIER_DATA', data);
            commit('SET_COURIER_META', meta);
            res(response);
          })
          .catch((error) => rej(error));
      });
    },
    fetchCourier({ commit }, { id, date }) {
      return new Promise((res, rej) => {
        httpClient
          .get(`/couriers/${id}?date=${date}`)
          .then((response) => {
            const { data } = response.data;
            commit('SET_COURIER', data);
            res(response);
          })
          .catch((error) => rej(error));
      });
    },
    createCourier({ dispatch }, payload) {
      return new Promise((res, rej) => {
        httpClient
          .post('/couriers', payload)
          .then((response) => {
            dispatch('fetchCouriers');
            res(response);
          })
          .catch((error) => rej(error));
      });
    },
    updateCourier(_, { id, payload }) {
      return new Promise((res, rej) => {
        httpClient
          .put(`/couriers/${id}`, payload)
          .then((response) => res(response))
          .catch((error) => rej(error));
      });
    },
    deleteCourier({ commit, state }, { id }) {
      return new Promise((res, rej) => {
        httpClient
          .delete(`/couriers/${id}`)
          .then((response) => {
            commit(
              'SET_COURIER_DATA',
              state.data.filter((item) => item.id !== id)
            );
            res(response);
          })
          .catch((error) => rej(error));
      });
    },
    bindCourierToOrder(_, { bindingsArray }) {
      return new Promise((res, rej) => {
        httpClient
          .post('/couriers/binding', { bindingsArray })
          .then((response) => res(response))
          .catch((error) => rej(error));
      });
    },
    unbindCourierToOrder(_, { courierId, orderId }) {
      return new Promise((res, rej) => {
        httpClient
          .delete(`/couriers/binding/${courierId}/order/${orderId}`)
          .then((response) => res(response))
          .catch((error) => rej(error));
      });
    },
    generateCourierFile(_, { id, params }) {
      return new Promise((res, rej) => {
        httpClient
          .get(`/couriers/${id}/file`, { params })
          .then((response) => {
            res(response.data);
          })
          .catch((error) => rej(error));
      });
    },
    deleteDocument(_, { id }) {
      return new Promise((res, rej) => {
        httpClient
          .delete(`/couriers/files/${id}`)
          .then((response) => {
            res(response.data);
          })
          .catch((error) => rej(error));
      });
    },
  },
};
