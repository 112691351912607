import router from '@/app/app-routes';
import store from '@/app/app-state';
import { computed, ref } from '@vue/composition-api';
import { Notification } from 'element-ui';
import Vue from 'vue';
import { COURIER_STORE_MODULE_NAME } from './courierStoreModule';

const routeData = Vue.observable({ params: {}, query: {} });

router.afterEach((route) => {
  routeData.params = route.params;
  routeData.query = route.query;
});

export function useCourierList() {
  const updateCourierRef = ref('');
  const createCourierRef = ref('');
  const documentsPreviewRef = ref(null);

  const previewFileTable = (url) => {
    documentsPreviewRef.value.open(url);
  };

  const generateFile = async ({ id }) => {
    const {
      data: { url },
    } = await store.dispatch(`${COURIER_STORE_MODULE_NAME}/generateCourierFile`, {
      id,
      query: routeData.query,
    });
    window.location.href = url;
  };

  const updateCourier = async (courier) => {
    const updateResult = await updateCourierRef.value.open(courier);
    if (updateResult) {
      Notification.success({
        title: 'Успех',
        message: 'Курьер успешно обновлен',
      });
    }
  };

  const createCourier = async () => {
    const createResult = await createCourierRef.value.open();
    if (createResult) {
      Notification.success({
        title: 'Успех',
        message: 'Курьер успешно добавлен',
      });
    }
  };

  const fetchCouriers = (params) => {
    store.dispatch(`${COURIER_STORE_MODULE_NAME}/fetchCouriers`, params);
  };

  const deleteCourier = ({ id }) => {
    try {
      store.dispatch(`${COURIER_STORE_MODULE_NAME}/deleteCourier`, { id });
      Notification.success({
        title: 'Успех',
        message: 'Курьер успешно удален.',
      });
    } catch (err) {
      Notification.error({
        title: 'Ошибка',
        message: 'Ошибка удаления.',
      });
    }
  };

  const meta = computed(() => store.state[COURIER_STORE_MODULE_NAME].meta);

  const data = computed(() => store.state[COURIER_STORE_MODULE_NAME].data);

  const changePage = (page) => {
    router.replace({ query: { page } });
    fetchCouriers({
      page,
    });
  };

  fetchCouriers({
    page: routeData.query.page || 1,
  });

  const goToClient = ({ id }) => {
    router.push(`/couriers/${id}`);
  };

  return {
    meta,
    data,
    changePage,
    deleteCourier,
    updateCourierRef,
    updateCourier,
    createCourier,
    createCourierRef,
    generateFile,
    previewFileTable,
    documentsPreviewRef,
    goToClient,
  };
}
