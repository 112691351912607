import { render, staticRenderFns } from "./courier-create.vue?vue&type=template&id=62fa5d20&"
import script from "./courier-create.vue?vue&type=script&lang=js&"
export * from "./courier-create.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports