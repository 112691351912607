import store from '@/app/app-state';
import { ref } from '@vue/composition-api';
import { COURIER_STORE_MODULE_NAME } from '../courierStoreModule';

export function useCourierCreate() {
  const isOpen = ref(false);
  const courier = ref({});
  const formСreateRef = ref(null);

  const rules = {
    name: [{ required: true, message: 'Укажите имя курьера', trigger: 'change' }],
  };

  let createDialogController = null;

  const open = () => {
    let resolve;
    let reject;
    const createDialogPromise = new Promise((ok, fail) => {
      resolve = ok;
      reject = fail;
    });
    createDialogController = { resolve, reject };
    isOpen.value = true;
    return createDialogPromise;
  };

  const saveCourier = () => {
    try {
      const { name } = courier.value;
      formСreateRef.value.validate((valid) => {
        if (valid) {
          store.dispatch(`${COURIER_STORE_MODULE_NAME}/createCourier`, { name });
          createDialogController.resolve(true);
          isOpen.value = false;
          courier.value = {};
          return;
        }
        return false;
      });
    } catch (e) {
      createDialogController.reject(false);
    }
  };

  return {
    isOpen,
    open,
    courier,
    saveCourier,
    rules,
    formСreateRef,
  };
}
