<template>
  <el-dialog title="Добавить курьера" :visible.sync="isOpen" width="40%">
    <el-form v-if="courier" ref="formСreateRef" :model="courier" :rules="rules" label-width="100px">
      <el-form-item label="Название" prop="name">
        <el-input v-model="courier.name" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="isOpen = false">Закрыть</el-button>
      <el-button type="primary" @click="saveCourier">Сохранить</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { useCourierCreate } from './useCourierCreate';

export default {
  name: 'CourierCreate',
  setup() {
    const { isOpen, open, courier, saveCourier, rules, formСreateRef } = useCourierCreate();

    return {
      isOpen,
      open,
      courier,
      saveCourier,
      rules,
      formСreateRef,
    };
  },
};
</script>
